
import { computed, defineComponent, Ref, ref } from "vue";
import Menuitem from "@/models/MenuItem";
import Menu from "primevue/menu";
import useOidc from "@/composables/useOidc";
import MenuItem from "@/models/MenuItem";
import useUser from "@/composables/useUser";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const menu = ref<Menu | null>(null);
    const menuUser = ref<Menu | null>(null);
    const oidc = useOidc();
    const user = useUser();

    return {
      oidcUser: oidc.user,
      user: user.user,
      menu,
      menuUser,
      menuItems: useMenuitems(),
      userMenuItems: useUserMenuItems(oidc.logOut, (path) => router.push(path)),
      toggle: (event: Event) => {
        menu.value?.toggle(event);
        menuUser.value?.toggle(event);
      },
    };
  },
});

function useMenuitems() {
  const user = useUser().user;
  return computed<MenuItem[]>(() =>
    user.value?.isSuperAdmin
      ? [
          {
            label: "Organisationer",
            to: "/organizations",
          },
          {
            label: "Applikationer",
            to: "/applications",
          },
          {
            label: "Brugere",
            to: "/users",
          },
          {
            label: "Indtillinger",
            to: "/setting",
          },
        ]
      : []
  );
}

function useUserMenuItems(
  logout: () => void,
  navigate: (path: string) => void
): Menuitem[] {
  return [
    {
      label: "Min profil",
      command: () => navigate("/me"),
    },
    {
      label: "Log ud",
      command: () => logout(),
    },
  ];
}
