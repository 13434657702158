import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import useOidc from "./composables/useOidc";
import { onError } from "@apollo/client/link/error";
import * as toast from "@/composables/toast";

export default function createApolloClient(apiUrl: string) {
  const cache = new InMemoryCache();

  const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: apiUrl,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = useOidc().user.value?.access_token;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError((error) => {
    if (error.graphQLErrors == null) return;
    if (error.graphQLErrors.length == 0) return;

    const data = error?.graphQLErrors[0]?.extensions?.["data"]?.errorDetail;
    toast.error(
      "Der skete en fejl",
      data?.Message,
      data?.CorrelationId,
      data?.Datetime
    );
  });

  return new ApolloClient({
    link: authLink.concat(errorLink).concat(httpLink),
    cache,
  });
}
