import Oidc, { User } from "oidc-client";
import { ref, h, watch } from "vue";
import * as constants from "@/constants";
import useGlobalLoading from "./useGlobalLoading";
import { RouteLocationNormalized, Router } from "vue-router";
import useUser from "./useUser";

const oidcManager = ref<Oidc.UserManager | null>(null);
const user = ref(null as User | null);

export default function () {
  async function initialize(
    authority: string,
    clientId: string,
    router: Router
  ) {
    useGlobalLoading().startLoading();

    oidcManager.value = new Oidc.UserManager({
      authority: authority,
      client_id: clientId,
      redirect_uri: `${window.location.origin}${constants.OIDC_CALLBACK_URI}`,
      response_type: "code",
      popup_post_logout_redirect_uri: constants.OIDC_LOGOUT_URI,
      scope: "openid",
      monitorSession: false,
      automaticSilentRenew: true,
      post_logout_redirect_uri: `${window.location.origin}${constants.OIDC_LOGOUT_URI}`,
      userStore: new Oidc.WebStorageStateStore({
        store: window.sessionStorage,
      }),
      accessTokenExpiringNotificationTime: constants.OIDC_EXPIRING_NOTIF_TIME,
    });

    user.value = await oidcManager.value?.getUser();

    handleManagerEvents(oidcManager.value, router);

    setupRouter(router);
    if (user.value) {
      useGlobalLoading().stopLoading();
    }
  }

  function handleManagerEvents(mgr: Oidc.UserManager, router: Router) {
    mgr.events.addUserLoaded((newUser) => {
      user.value = newUser;
    });

    mgr.events.addUserUnloaded(() => {
      user.value = null;
    });

    mgr.events.addAccessTokenExpired(() => {
      user.value = null;
      // router.push("/loggedout");
    });

    mgr.events.addUserSignedOut(() => {
      user.value = null;
    });
  }

  function setupRouter(router: Router) {
    router.addRoute({
      path: constants.OIDC_CALLBACK_URI,
      meta: {
        isPublic: true,
      },
      component: {
        render: () => h("div"),
        created() {
          oidcManager.value?.signinRedirectCallback().then((data) => {
            const redirect = data.state ? data.state.to : null;
            watch(
              () => useUser().user.value,
              (newValue) => {
                if (newValue != null) {
                  if (newValue.isSuperAdmin) {
                    router.replace(redirect || "/");
                  } else if (newValue.isAdmin) {
                    // User is not super-admin, and should therefore be redirected to his organization, or if trying to reach a resource without access see error.
                    router.replace(
                      redirect.path == "/organizations"
                        ? newValue.userHomePage
                        : redirect
                    );
                  } else {
                    router.replace(newValue.userHomePage);
                  }
                  useGlobalLoading().stopLoading();
                }
              },
              { immediate: true }
            );
          });
        },
      },
    });

    router.addRoute({
      path: constants.OIDC_LOGOUT_URI,
      meta: {
        isPublic: true,
      },
      component: () => import("@/views/LoggedOut.vue"),
    });

    router.beforeEach(async (to, from, next) => {
      if (to.matched[0].meta.isPublic) {
        useGlobalLoading().stopLoading();
        return next();
      } else if (user.value && !user.value.expired) {
        return next();
      } else {
        const result = await oidcManager.value?.signinRedirect({
          state: { to },
        });
        if (result) {
          return next();
        } else {
          return next(false);
        }
      }
    });
  }

  function logOut() {
    const token = user.value?.id_token;
    oidcManager.value?.signoutRedirect({ id_token_hint: token });
  }

  function forceLogin(to: RouteLocationNormalized) {
    oidcManager.value?.signinRedirect({ state: { to } });
  }

  function onSoonLoggedOut(cb: (number: number) => void) {
    oidcManager.value?.events.addAccessTokenExpiring(() => {
      cb(constants.OIDC_EXPIRING_NOTIF_TIME);
    });
  }

  return {
    initialize,
    logOut,
    forceLogin,
    user,
    onSoonLoggedOut,
  };
}
