import { UserProfileType } from "@/graphql/types";
import { ref, readonly, computed } from "vue";

interface WithHomePage {
  userHomePage: string;
}

const user = ref<(UserProfileType & WithHomePage) | undefined>();

export default function () {
  function setUser(profileType: UserProfileType | undefined) {
    user.value = profileType
      ? {
          ...profileType,
          userHomePage: profileType.isSuperAdmin
            ? "/"
            : profileType.isAdmin
            ? `/organizations/${profileType.organizationId}`
            : "/me",
        }
      : undefined;
  }

  const isGlobalAdmin = computed<boolean>(
    () => user.value?.isSuperAdmin ?? false
  );

  const isLocalAdmin = computed<boolean>(() => user.value?.isAdmin ?? false);

  return {
    user: readonly(user),
    setUser,
    isGlobalAdmin,
    isLocalAdmin,
  };
}
