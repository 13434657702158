import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmPopup = _resolveComponent("ConfirmPopup")!
  const _component_ToastMessage = _resolveComponent("ToastMessage")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_splash = _resolveComponent("splash")!
  const _component_navigation_bar = _resolveComponent("navigation-bar")!
  const _component_bread_crumb = _resolveComponent("bread-crumb")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_ConfirmPopup),
    _createVNode(_component_Toast, {
      style: {"z-index":"2000"},
      autoZIndex: false,
      position: "top-right"
    }, {
      message: _withCtx((slotProps) => [
        _createVNode(_component_ToastMessage, {
          message: slotProps.message
        }, null, 8, ["message"])
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_splash, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_navigation_bar),
    (_ctx.user?.isSuperAdmin)
      ? (_openBlock(), _createBlock(_component_bread_crumb, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}