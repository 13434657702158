
import { defineComponent, ref } from "vue";
import { formatDateTime } from "@/utils/date";

export default defineComponent({
  name: "ToastMessage",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isErrorToast = props.message.severity === "error";
    const toggleDetail = ref(false);

    const onToggleDetail = () => {
      toggleDetail.value = !toggleDetail.value;
    };

    return {
      isErrorToast,
      icon: isErrorToast ? "exclamation-triangle" : "check",
      onToggleDetail,
      toggleDetail,
      formatDateTime,
    };
  },
});
