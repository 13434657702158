import { isNullEmptyOrUndefined } from "./mist";

export function formatDate(
  dateStr: string,
  includeTime = false,
  includeYear = true
): string {
  if (isNullEmptyOrUndefined(dateStr)) {
    return "";
  }

  const date = new Date(dateStr);

  if (date.toString() === "Invalid Date") {
    return "";
  }

  const dateOption: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
  };
  if (includeYear) {
    dateOption.year = "numeric";
  }

  const locale = "da";

  const datePart = date
    .toLocaleDateString(locale, dateOption)
    .replaceAll("/", ".");

  let timePart: string | undefined;

  if (includeTime) {
    timePart = date
      .toLocaleTimeString(locale, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(".", ":");
  }

  return includeTime ? `${datePart} - ${timePart}` : `${datePart}`;
}

export function formatDateTime(value: string): string {
  const date = new Date(value);
  return date.toLocaleString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}
