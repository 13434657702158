import toasteventbus from "primevue/toasteventbus";
import { TOAST_DURATION } from "@/constants";

export function info(
  summary: string,
  detail: string,
  correlationId: string,
  datetime: string,
  life: number = TOAST_DURATION
) {
  toasteventbus.emit("add", {
    severity: "info",
    summary,
    detail,
    correlationId,
    datetime,
    life,
  });
}

export function warn(
  summary: string,
  detail: string,
  correlationId: string,
  datetime: string,
  life: number = TOAST_DURATION
) {
  toasteventbus.emit("add", {
    severity: "warn",
    summary,
    detail,
    correlationId,
    datetime,
    life,
  });
}

export function success(
  summary: string,
  detail: string,
  correlationId: string,
  datetime: string,
  life: number = TOAST_DURATION
) {
  toasteventbus.emit("add", {
    severity: "success",
    summary,
    detail,
    correlationId,
    datetime,
    life,
  });
}

export function error(
  summary: string,
  detail: string,
  correlationId: string,
  datetime: string,
  life: number = TOAST_DURATION
) {
  toasteventbus.emit("add", {
    severity: "error",
    summary,
    detail,
    correlationId,
    datetime,
    life,
  });
}
